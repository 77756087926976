<template>
  <div class="send-text-content">
    <div style="flex: 1">
      <div v-if="showOperation">
        <a-button
            type="primary" size="small" class="chat-button"
            icon="plus-circle"
            @click="addChatTheme"
        >新建对话
        </a-button>
        <a-button
            type="primary"
            size="small" ghost
            class="chat-button"
            @click="prompts"
        >
          提示词
        </a-button>
        <!--        <a-button type="primary" size="small" ghost class="chat-button">对话记录</a-button>-->
        <a-dropdown>
          <a-button class="chat-button" size="small" type="primary" ghost @click="e => e.preventDefault()">
            已选择模型：{{ modelValue }}
          </a-button>
          <a-menu slot="overlay">


            <a-menu-item v-for="item  in modes" @click="checkModel(item)">{{ item.name }}</a-menu-item>
            <!--            <a-menu-item @click="()=>{currentModel={name: 'GPT-3', value:'gpt3'}}">GPT-3</a-menu-item>-->
            <!--            <a-menu-item @click="()=>{currentModel={name: 'GPT-4', value:'gpt4'}}">GPT-4</a-menu-item>-->
          </a-menu>
        </a-dropdown>
      </div>
      <a-textarea
          v-model="message"
          @keydown.native="handleKeyCode($event)"
          placeholder="请输入内容后【回车】发送, 【Ctrl+回车】换行，快去试试吧～"/>
      <div class="ext-footer">
        <a-tooltip placement="top">
          <template slot="title">
            <span>该服务处于前沿探索阶段，您应当合法合规使用本服务，并承担由此产生的所有责任。我们对您的使用不做保证且不承担任何责任。</span>
          </template>
          <icon-font type="icon-mianzeshuoming" class="icon"/>
          免责声明
        </a-tooltip>
      </div>
    </div>

    <a-button type="primary"
              @click="sendMessage"
              size="small"
              :disabled="!message.trim()"
              style="margin: 38px 0 0 15px;">发送
    </a-button>

    <a-modal
        v-if="visible"
        v-model="visible"
        centered
        :footer="null"
        :maskClosable="false"
        width="85%"
        :destroyOnClose="true">
      <div class="wrapper" :style="{height: panelHeight + 'px'}">
        <PromptsDetails @clickCard="clickCard"/>
      </div>
    </a-modal>


    <a-modal
        :title="'提示词变量替换'"
        v-if="showReplace"
        v-model="showReplace"
        centered
        :footer="null"
        :maskClosable="false"
        :width="580"
        :destroyOnClose="true">
      <ReplaceForm :formsText="formsText"/>
    </a-modal>
  </div>
</template>

<script>
import {userModel} from "@/api/chat";

export default ({
  components: {
    PromptsDetails: () => import('@/views/application/chat/components/PromptsDetails.vue'),
    ReplaceForm: () => import('@/views/application/chat/components/ReplaceForm.vue')
  },
  props: {
    showOperation: {
      type: Boolean,
      default: true
    },
    modelName: {
      type: String,
      default: ''
    }
  },
  computed: {
    modelValue:{
      get(){
        return this.modelName
      },
      set(newValue){
        this.$emit('update:modelName', newValue)
      }
    },
    panelHeight: {
      get() {
        return window.innerHeight - 120
      }
    }
  },
  data() {
    return {
      message: '',
      visible: false,
      showReplace: false,
      formsText: [],
      // currentModel: {
      //   name: 'GPT-3',
      //   value: 'gpt3'
      // },
      modes: []
    }
  },
  methods: {
    checkModel(item) {
      this.modelValue = item.value
    },
    init() {
      userModel().then(res => {
        this.modes = res.data
        this.modelValue = res.data[0].value
      })
    },
    handleKeyCode(event) {
      if (event.keyCode === 13) {
        if (!event.ctrlKey) {
          event.preventDefault();
          this.sendMessage()
        } else {
          this.message += '\n';
        }
      }
    },
    clickCard(card) {
      this.visible = false
      if (card) {
        const regex = /\{([^{}]+)}/g;
        const matches = card.content.match(regex);
        if (matches && matches.length > 0) {
          this.showReplace = true
          this.formsText = []
          matches.forEach(item => {
            this.formsText.push({
              name: item,
              value: ''
            })
          })
        } else {
          this.message = card.content
        }
      }
    },
    sendMessage() {
      this.$emit('sendMessage', this.message, this.modelValue)
      this.message = ''
    },
    addChatTheme() {
      this.$emit('addChatTheme')
    },
    prompts() {
      this.visible = true
      // this.$message.warning('提示词')
    }
  },
  mounted() {
    this.init()
  }
})
</script>

<style scoped lang="scss">
.send-text-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding: 0 20px 20px 20px;

  .chat-button {
    margin: 0 15px 15px 0;
  }

  .ext-footer {
    background: #fff;
    border-radius: 0 0 5px 5px;
    padding: 3px 10px;
    color: #BFBFBF;
    font-size: 12px;
  }

}

::v-deep textarea {
  resize: none;
  height: 60px;
  border: none;
  font-weight: normal;
  border-radius: 5px 5px 0 0;
}
</style>